/**
 * The session cookie name is different per environment because each environment
 * has a different secret used to sign the cookie. We differentiate the cookie
 * name so that when we try to access the cookie, we don't grab one from the
 * wrong environment and try to verify it with a mismatched secret. Atm, it
 * isn't possible to get a cookie based on both its name and domain.
 * https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#why-wont-you-let-me-get-a-cookie-with-a-particular-attribute-say-path-or-domain
 */
export const SESSION_COOKIE_NAME =
  'hw_session' +
  (process.env.NODE_ENV === 'development'
    ? '_development'
    : process.env.NEXT_PUBLIC_SESSION_COOKIE_DOMAIN?.includes('test')
      ? '_test'
      : '');

export const SESSION_COOKIE_MAX_AGE_MILLISECONDS = 1000 * 60 * 60 * 24 * 7; // 7 days

export const PATIENT_GOOGLE_CLICK_ID_COOKIE_NAME = 'hw_patient_gclid';
