import React, { useEffect } from 'react';

import { useFlag } from '@headway/feature-flags/react';
import {
  FORETHOUGHT_LIVE_CHAT_ENABLED,
  LIVE_CHAT_INSURANCE_VERIFICATION,
  USE_ZENDESK_MESSAGING,
} from '@headway/shared/FeatureFlags/flagNames';
import { formatPatientName } from '@headway/shared/utils/patient';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { ForethoughtScriptProvider as BaseForethoughtScriptProvider } from '@headway/ui/Forethought';

import { useRouter } from '../hooks/useRouter';
import { withStores, WithStoresChildProps } from '../stores/withStores';

const ForethoughtScriptImpl: React.FC<
  WithStoresChildProps & React.PropsWithChildren
> = ({ AuthStore, children }) => {
  const isForethoughtFFEnabled = useFlag('forethoughtEnabled');
  const isLiveChatEnabled = useFlag(FORETHOUGHT_LIVE_CHAT_ENABLED);
  const isInsuranceVerificationViaLiveChatEnabled = useFlag(
    LIVE_CHAT_INSURANCE_VERIFICATION,
    false
  );
  const isZendeskMessagingEnabled = useFlag(USE_ZENDESK_MESSAGING, false);

  const isRegisteredUser =
    getVerificationStatus(AuthStore.user) ===
    UserVerificationStatus.REGISTERED_AND_VERIFIED;

  const router = useRouter();
  const openChatbot = router.query.openChatbot === 'true';

  // If they're not logged in, but the flag is enabled, as for login when we want to force the bot open
  useEffect(() => {
    if (
      (!isRegisteredUser || !AuthStore.user.email) &&
      isForethoughtFFEnabled &&
      openChatbot
    ) {
      AuthStore.redirectToAuth0Login();
    }
  }, [AuthStore, isForethoughtFFEnabled, isRegisteredUser, openChatbot]);

  if (!isForethoughtFFEnabled || !isRegisteredUser || !AuthStore.user.email) {
    return <>{children}</>;
  }

  const isProviderOrGPOwner =
    isRegisteredUser &&
    (!!AuthStore.user?.providerId ||
      !!AuthStore.user?.groupPracticeUsers?.length);

  const workflowTagValue = isProviderOrGPOwner
    ? undefined
    : 'I receive mental health services';

  const name = formatPatientName(AuthStore.user);

  return (
    <BaseForethoughtScriptProvider
      mambaHost={process.env.API_URL}
      zendeskAPIKey={process.env.NEXT_PUBLIC_ZENDESK_CHAT_API_KEY}
      apiKey={process.env.NEXT_PUBLIC_FORETHOUGHT_DATA_API_KEY}
      email={AuthStore.user.email}
      name={name}
      defaultWorkflowTag={workflowTagValue}
      providerID={AuthStore.user?.providerId}
      userID={AuthStore.user.id}
      autoOpen={openChatbot}
      liveChatEnabled={isLiveChatEnabled}
      insuranceVerificationLiveChat={isInsuranceVerificationViaLiveChatEnabled}
      zendeskMessagingEnabled={isZendeskMessagingEnabled}
    >
      {children}
    </BaseForethoughtScriptProvider>
  );
};

const ForethoughtScript = withStores(ForethoughtScriptImpl);
export { ForethoughtScript };
