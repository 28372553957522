import { datadogRum } from '@datadog/browser-rum';
import Cookies from 'js-cookie';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { BookingAvailabilityEventSelection } from '@headway/shared/types/booking';
import {
  aliasUser,
  identifyUser as identifyUserInAnalytics, // trackEvent,
} from '@headway/shared/utils/analytics';
import { isAppointmentReady } from '@headway/shared/utils/providerFrontEndCarrier';
import { logException } from '@headway/shared/utils/sentry';
import { isUserRegistered } from '@headway/shared/utils/userVerificationStatus';

import {
  PATIENT_GOOGLE_CLICK_ID_COOKIE_NAME,
  SESSION_COOKIE_NAME,
} from '../constants/cookie';

/**
 * Decodes the current session cookie and returns the session id
 */
const decodeSessionIdCookie = (): string | undefined => {
  try {
    const cookie = Cookies.get(SESSION_COOKIE_NAME);
    if (!cookie) return;

    const sessionValue = atob(cookie);
    const sessionJson = JSON.parse(sessionValue);
    const sessionId =
      !!sessionJson && typeof sessionJson === 'object' && 'id' in sessionJson
        ? sessionJson.id
        : undefined;
    return sessionId;
  } catch (err) {
    const isJsonParseError =
      err instanceof SyntaxError &&
      (err.message.toLowerCase().includes('json parse') ||
        err.message.toLowerCase().includes('json.parse'));
    if (!isJsonParseError) {
      // Ignore JSON parse errors--the cookie value might be null and unparsable
      logException(err);
    }
    // Don't throw any errors if we can't get the session id--it's not critical
    return;
  }
};

/**
 * Identify the user in our analytics platform(s). Wraps identifyUser() from
 * @headway/shared to send Agora-specific information such as session ID.
 * @param user The user to identify
 * @param prevUser The previous user before the current one logged in (since we
 * have database users for unauthenticated visitors)
 */
export const identifyUser = (
  user: UserRead,
  prevUser?: UserRead | null,
  identifyUserInAnalyticsParams?: {
    traits: Parameters<typeof identifyUserInAnalytics>[0]['traits'];
    options: Parameters<typeof identifyUserInAnalytics>[0]['options'];
    onComplete: Parameters<typeof identifyUserInAnalytics>[0]['onComplete'];
  }
) => {
  const shouldIdentifyUser =
    !!user &&
    (user.id !== prevUser?.id ||
      user.email !== prevUser?.email ||
      user.firstName !== prevUser?.firstName ||
      user.lastName !== prevUser?.lastName ||
      user.isQuestionnaireTaken !== prevUser?.isQuestionnaireTaken ||
      user.isVerified !== prevUser?.isVerified);

  if (!shouldIdentifyUser) {
    return;
  }

  const isAuthenticated = isUserRegistered(user);
  const isPrevAuthenticated = !!prevUser && isUserRegistered(prevUser);
  const loggingIn = !!prevUser && !isPrevAuthenticated && isAuthenticated;

  // Link previously anonymous user <> registered/logged-in user
  if (loggingIn) {
    aliasUser(user.id.toString());
  }

  // Set the user for the datadog RUM tracing
  datadogRum.setUser({
    id: user.id.toString(),
    isAuthenticated: isAuthenticated,
  });

  identifyUserInAnalytics({
    id: user.id.toString(),
    isAuthenticated,
    traits: {
      email: user.email,
      session_id: decodeSessionIdCookie(),
      isLoggedIn: !!user.email,
      ...identifyUserInAnalyticsParams?.traits,
      ...getAdAnalyticsTraits(),
    },
    options: {
      integrations: {
        All: true,
        Salesforce: false,
        'Google Analytics': false,
        'Google Analytics 4': false,
        ...identifyUserInAnalyticsParams?.options?.integrations,
      },
      ...identifyUserInAnalyticsParams?.options,
    },
    onComplete: identifyUserInAnalyticsParams?.onComplete,
  });
};
const getAdAnalyticsTraits = () => {
  // Check if we're in a browser environment
  const isBrowser =
    typeof window !== 'undefined' && typeof document !== 'undefined';

  const googleClickId = isBrowser
    ? document.cookie
        .split('; ')
        .find((row) =>
          row.startsWith(`${PATIENT_GOOGLE_CLICK_ID_COOKIE_NAME}=`)
        )
        ?.split('=')[1]
    : undefined;

  return googleClickId ? { gclid: decodeURIComponent(googleClickId) } : {};
};

/*
 * The below functions with the pattern getXAnalytics return objects that represent
 * event property groups (aka contexts) that are defined in Avo.
 */

export const getProviderAnalytics = (provider: ProviderRead) => ({
  providerId: provider.id,
  providerType: provider.providerType,
  providerFunction: provider.providerFunction,
  providerLicenseType: provider.licenseType,
  providerPrimaryStateId: provider.primaryStateId,
  providerStateOfResidence: provider.stateOfResidence,
  providerPhotoStatus: provider.isPhotoApproved
    ? 'approved'
    : provider.photoUrl
      ? 'uploaded'
      : 'none',
  providerRequiresIntakeCall: provider.requiresIntakeCall,
  providerIsPrescriber: provider.isPrescriber,
});

export const getProviderFrontEndCarrierAnalytics = (
  providerCarrier: ProviderFrontEndCarrierRead
) => ({
  credentialed: isAppointmentReady(providerCarrier),
  hiddenFromProfile: providerCarrier.hiddenFromProfile,
  ...getCarrierAnalytics(providerCarrier.frontEndCarrier),
});

export const getCarrierAnalytics = (
  carrier: Pick<FrontEndCarrierRead, 'id' | 'name'>
) => ({
  carrierId: carrier.id,
  carrierName: carrier.name,
});

export const getAppointmentAnalytics = (
  event:
    | BookingAvailabilityEventSelection
    | AvailabilityEventResponse
    | ConcreteProviderEventRead
): {
  appointmentBillingType: 'insurance' | 'self_pay';
  appointmentMedium: 'in_person' | 'phone_call' | 'video';
  appointmentType: 'intake_call' | 'appointment';
  providerAppointmentId: number | undefined;
  providerEventId: number | undefined;
} => ({
  providerEventId: 'id' in event ? event.id : undefined,
  providerAppointmentId:
    !!event.providerAppointment && 'id' in event.providerAppointment
      ? event.providerAppointment.id
      : undefined,
  // See BookingAvailability form onSubmit to understand fields for availabilities
  appointmentType: event.type.toLowerCase() as 'intake_call' | 'appointment',
  appointmentBillingType: 'insurance',
  appointmentMedium:
    event.type === ProviderEventType.INTAKE_CALL
      ? 'phone_call'
      : event.providerAddressId
        ? 'in_person'
        : 'video',
});

// TODO (soleil/sc-124213): find new place for this
/** Tracks an event for when a user requests email updates when a market goes live. */
export const trackInsuranceEmailRequestForMarket = (
  email: string,
  market: string
) => {
  // trackEvent({
  //   name: 'Add Insurance Market Request',
  //   properties: {
  //     insuranceError: 'MARKET_NOT_ON_HW',
  //     patientEmail: email,
  //     marketRequested: market,
  //   },
  // });
};

// TODO (soleil/sc-124213): find new place for this
/** Tracks an event for when a user requests email updates when Headway starts accepting a carrier. */
export const trackInsuranceEmailRequestForCarrier = (
  email: string,
  carrierId: number
) => {
  // trackEvent({
  //   name: 'Add Insurance Payer Request',
  //   properties: {
  //     insuranceError: 'CARRIER_NOT_ON_HW',
  //     patientEmail: email,
  //     carrierIdRequested: carrierId,
  //   },
  // });
};

export enum AnalyticsPatientFlow {
  ADD_INSURANCE = 'add_insurance',
  BOOK_APPOINTMENT = 'book_appointment',
  CREATE_ACCOUNT = 'create_account',
  LOG_IN = 'log_in',
  RESCHEDULE_APPOINTMENT = 'reschedule_appointment',
  SCHEDULE_APPOINTMENT = 'schedule_appointment',
  ACCOUNT_INFO = 'account_info',
}
