import * as Sentry from '@sentry/nextjs';
import { AxiosError, isCancel } from 'axios';

import { MambaApiError } from '@headway/api/axios.config';

type Metadata = {
  tags: Record<string, string>;
  fingerprints: string[];
  message?: string;
};

type SentryClientConfig = Parameters<typeof Sentry['init']>[0];

// because this config is shared across client and server, we want to limit
// what can be configured globally
type SharedSentryClientConfig = Pick<
  SentryClientConfig,
  'dsn' | 'environment' | 'release'
>;

export const getSharedSentryClientConfig = (): SharedSentryClientConfig => {
  return {
    dsn: process.env.SENTRY_PUBLIC_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  };
};

export const getMetadataForNetworkCallError = (error: AxiosError): Metadata => {
  const metadata: Metadata = {
    tags: {
      'request.was_canceled': isCancel(error).toString(),
    },
    fingerprints: [],
  };

  if (error.config?.baseURL) {
    metadata.tags['request.base_url'] = error.config.baseURL;
    metadata.fingerprints.push(`request.base_url:${error.config.baseURL}`);
  }
  if (error instanceof MambaApiError) {
    // the `apiRoute` is the request's path without the variables injected.
    // E.g., `/my-resource/{resourceId}` instead of `/my-resource/123`
    // This is a custom property we add in the Axios client
    metadata.tags['request.mamba_api_route'] = error.apiRoute;
    metadata.fingerprints.push(`request.mamba_api_route:${error.apiRoute}`);
    if (error.config?.method) {
      metadata.message = `${error.config.method.toUpperCase()} ${
        error.apiRoute
      } failed`;

      if (error.response?.status) {
        metadata.message += ` with status ${error.response.status}`;
      }
    }
  }
  if (error.config?.url) {
    // the `url` is the request's path with the variables injected.
    // E.g., `/my-resource/123` instead of `/my-resource/{resourceId}`
    // We don't want to fingerprint on this because the fingerprints will change
    // every time a new different resource is requested
    metadata.tags['request.raw_path'] = error.config.url;
  }
  if (error.config?.method) {
    metadata.tags['request.method'] = error.config.method.toUpperCase();
    metadata.fingerprints.push(
      `request.method:${error.config.method.toUpperCase()}`
    );
  }
  if (error.response?.status) {
    metadata.fingerprints.push(
      `request.response_status_code:${error.response.status}`
    );
  }

  return metadata;
};
