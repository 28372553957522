import '@babel/polyfill';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { CacheProvider } from '@emotion/react';
import * as Sentry from '@sentry/nextjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import memoize from 'lodash/memoize';
import { configure as configureMobx } from 'mobx';
import { enableStaticRendering, Provider } from 'mobx-react';
import { DefaultSeo } from 'next-seo';
import App from 'next/app';
import React from 'react';

import { configureMambaUrl } from '@headway/api';
import { IdentifyFlagsContext, LDProvider } from '@headway/feature-flags/react';
import '@headway/helix/assets/helix.css';
import { HelixProvider, HelixSSRProvider } from '@headway/helix/Provider';
import { ToastContainer } from '@headway/helix/Toast';
import { QueryCache, QueryClient } from '@headway/shared/react-query';
import { Hydrate, QueryClientProvider } from '@headway/shared/react-query';
import { getApplicationName } from '@headway/shared/utils/request';
import {
  logException,
  setErrorLogDestination,
} from '@headway/shared/utils/sentry';
import { ToastManager } from '@headway/ui';
import { MarketProvider } from '@headway/ui/providers/MarketProvider';
import { ThemeProvider } from '@headway/ui/theme';

import ogImage from '../assets/img/ogimage.png';
import '../assets/scss/material-kit-pro-react.scss';
import '../assets/styles/global.css';
import { ForethoughtScript } from '../components/ForethoughtScript';
import { ImpersonatingUserProvider } from '../components/ImpersonatingUserProvider';
import { BillingEventsProvider } from '../contexts';
import { ApplicationNameContextProvider } from '../contexts/ApplicationNameContext';
import {
  buildLaunchDarklyContextFromRequest,
  buildLaunchDarklyContextFromUser,
} from '../lib/featureFlags/context/builder';
import { initializeAuthStore } from '../stores/AuthStore';
import { initializeUiStore } from '../stores/UiStore';
import { withStores } from '../stores/withStores';
import { getApiBaseUrl } from '../utils/apiUrl';
import { getAuthCookie } from '../utils/cookie';
import createEmotionCache from '../utils/createEmotionCache';
import '../utils/formatJSPolyfills';
import { isUnauthenticatedError } from '../utils/query';
import { storeSearchParams } from '../utils/searchParamStorage';

const getLaunchDarklySDKKey = () => {
  if (process.env.FLAGS_SDK_KEY) {
    return process.env.FLAGS_SDK_KEY;
  }

  throw new Error('FLAGS_SDK_KEY not found in environment variables');
};
/*
enable Sentry in:
- production = our production environment
- development = development.therapymatch.info
*/
const isStagingOrProdSentry = ['development', 'production'].includes(
  process.env.SENTRY_ENVIRONMENT
);

setErrorLogDestination(isStagingOrProdSentry ? 'SENTRY' : 'CONSOLE');
configureMobx({ enforceActions: 'never' });

enableStaticRendering(typeof window === 'undefined');

const clientSideEmotionCache = memoize(createEmotionCache);
configureMambaUrl(getApiBaseUrl());

// we need to wrap this component with an injected auth store so that it will
// re-render when the user id changes

class ImpersonatingUserProviderWithUserIdImpl extends React.Component {
  render() {
    const {
      AuthStore: { user },
      AuthStore,
      ...rest
    } = this.props;
    return <ImpersonatingUserProvider actingUserId={user?.id} {...rest} />;
  }
}

const ImpersonatingUserProviderWithUserId = withStores(
  ImpersonatingUserProviderWithUserIdImpl
);
class MyApp extends App {
  static async getInitialProps(appContext) {
    const uiStore = initializeUiStore();
    const authStore = initializeAuthStore();

    let appName;

    if (typeof window === 'undefined') {
      appName = getApplicationName(appContext.ctx.req);
    } else {
      appName = getApplicationName(window.location.href);
    }

    // if we're on the server, make sure we load the user first before making any other API calls
    // if we're on the client, the user has already been loaded on the server and passed to the client
    // so no need to fetch again
    let initialLdContext;
    let initialLdFlags;
    let nonce;
    let isBot = false;
    if (typeof window === 'undefined') {
      const { req, res } = appContext.ctx;
      nonce = req.nonce;
      isBot = req.isBot;
      authStore.setUser(req.userMeData);

      const cookie = getAuthCookie(req, res);

      const ld = await import('@headway/feature-flags/node');

      await ld.initialize(getLaunchDarklySDKKey);
      // We load all flags to hydrate the client to avoid page-flickers on initial page-load.
      // eslint-disable-next-line no-restricted-syntax
      const requestLdContext = buildLaunchDarklyContextFromRequest(req);
      const allFlags = await ld.getAllFlags(requestLdContext);
      initialLdFlags = allFlags.toJSON();
      initialLdContext = requestLdContext;
      // create an axios config with the session cookie to use in later requests
      appContext.ctx.axiosConfig = {
        headers: {
          cookie,
        },
      };
    }

    appContext.ctx.uiStore = uiStore;
    appContext.ctx.authStore = authStore;

    let appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      appName,
      initialUiState: uiStore,
      initialAuthState: authStore,
      initialLdContext,
      initialLdFlags,
      isBot,
      nonce,
    };
  }

  // We only retrieve initialLdFlags and initialLdContext when getInitialProps runs on the server.
  // Store initialLdFlags and initialLdContext so we can still initialize the LaunchDarkly client
  // when page changes don't require the server
  savedLdFlags = this.props.initialLdFlags;
  savedLdContext = this.props.initialLdContext;
  uiStore = initializeUiStore(this.props.initialUiState);
  authStore = initializeAuthStore(this.props.initialAuthState);
  queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError(error) {
        logException(error);
      },
    }),
    defaultOptions: {
      queries: {
        // These feel like sane defaults for most queries.  Our data does not change
        // at a very high frequency and usually the changes are a direct result of
        // actions taken by a user and thus should be handled via manual cache updates.
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,

        // We can enable this if we start seeing unexpected failures due to network conditions
        // or similar, but for now the assumption is that errors are due to unforeseen
        // conditions or logic bugs, no need to retry 3 times.
        retry: false,
      },
    },
  });

  componentDidCatch(error, errorInfo) {
    const scope = Sentry.getCurrentScope();
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });

    Sentry.captureException(error);
  }

  componentDidMount() {
    // disable RUM for the session if the userAgent appears to be a bot
    const sampleRate = this.props.isBot ? 0 : 100;
    // Initialize Datadog Real User Metrics
    datadogRum.init({
      applicationId: process.env.DD_APPLICATION_ID,
      clientToken: process.env.DD_RUM_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'patient-web',
      env: process.env.NEXT_PUBLIC_DD_ENV ?? process.env.ENVIRONMENT ?? 'local',
      version: process.env.NEXT_PUBLIC_DD_VERSION,
      sessionSampleRate: sampleRate,
      sessionReplaySampleRate: sampleRate,
      // Don't edit me! This is the default value for privacy level. To Expose a single element add data-dd-privacy="allow" to your element
      defaultPrivacyLevel: 'mask',
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      // Enable Launch Darkly integration
      enableExperimentalFeatures: ['feature_flags'],
      // This connects the RUM data to the DD Traces coming out of Express/ Mamba/ Postgres
      allowedTracingUrls: [
        process.env.API_URL,
        process.env.NEXT_PUBLIC_CARE_URL,
      ],
      beforeSend: (event) => {
        // remove sensitive fields from view url
        const fieldsToRemove = [
          'issues',
          'lat',
          'lon',
          'upperLat',
          'lowerLat',
          'upperLon',
          'lowerLon',
          'state',
          'email',
          'password',
        ];
        fieldsToRemove.forEach((field) => {
          event.view.url = event.view.url.replace(
            new RegExp(`(${field}=)[^&]*(?=&|$)`, 'g'),
            `${field}=REDACTED`
          );
          event.view.referrer.replace(
            new RegExp(`(${field}=)[^&]*(?=&|$)`, 'g'),
            `${field}=REDACTED`
          );
        });

        // Remove the provider name from the view name
        event.view.url = event.view.url.replace(
          /providers\/[^\/?]+/,
          'providers/PROVIDER-SLUG'
        );

        event.view.referrer = event.view.referrer.replace(
          /providers\/[^\/?]+/,
          'providers/PROVIDER-SLUG'
        );

        if (event.type === 'action') {
          // Check if the target element is opted into tracking by providing the data-dd-action-name attribute
          // Doing this because Datadog will use the label / inner text if this attribute doesn't exist
          // Being careful here around sending any text to Datadog that we don't explicitly intend to
          if (
            (event.action && event.action.type === 'custom') ||
            (event._dd.action &&
              event._dd.action.target.selector.includes('data-dd-action-name'))
          ) {
            // Keep the event
            return true;
          } else {
            // Discard the event
            return false;
          }
        } else if (event.type === 'error') {
          // ignoring CSP violations in datadog rum
          // because these are security errors that Security started to spin up but never finished
          if (event.error.message.match(/csp_violation/i)) {
            return false;
          }
        }
      },
      // only enable if on HTTPS
      useSecureSessionCookie: true,
      // disable telemetry data sent to DataDog
      telemetrySampleRate: 0,
    });

    datadogLogs.init({
      clientToken: process.env.DD_RUM_CLIENT_TOKEN,
      service: 'patient-web',
      site: 'datadoghq.com',
      sessionSampleRate: sampleRate,
      env: process.env.NEXT_PUBLIC_DD_ENV ?? process.env.ENVIRONMENT ?? 'local',
      version: process.env.NEXT_PUBLIC_DD_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      // only enable if on HTTPS
      useSecureSessionCookie: true,
      // disable telemetry data sent to DataDog
      telemetrySampleRate: 0,
    });
    // store any search params for tracking so they don't get trimmed by navigation
    storeSearchParams();
  }

  render() {
    const {
      Component,
      initialLdContext,
      initialLdFlags,
      pageProps,
      // _sometimes_ nonce gets lost during client side navigation
      // thus use the variable set by the server in _document.js
      emotionCache = clientSideEmotionCache(
        typeof window !== 'undefined'
          ? window.__webpack_nonce__
          : this.props.nonce
      ),
      appName,
    } = this.props;

    return (
      <HelixSSRProvider>
        <HelixProvider>
          <CacheProvider value={emotionCache}>
            <DefaultSeo
              titleTemplate="%s | Headway"
              openGraph={{
                type: 'website',
                site_name: 'Headway',
                images: [
                  {
                    url: `${process.env.NEXT_PUBLIC_CARE_URL}${ogImage.src}`,
                    width: ogImage.width,
                    height: ogImage.height,
                    alt: 'Headway landing page',
                  },
                ],
              }}
              twitter={{
                site: '@try_headway',
                cardType: 'summary_large_image',
              }}
            />

            <ThemeProvider>
              <Provider UiStore={this.uiStore} AuthStore={this.authStore}>
                <QueryClientProvider client={this.queryClient}>
                  <ApplicationNameContextProvider value={appName}>
                    <Hydrate state={pageProps.dehydratedState}>
                      <ToastContainer />
                      <MarketProvider>
                        <LDProvider
                          clientSideID={process.env.NEXT_PUBLIC_FLAGS_CLIENT_ID}
                          context={initialLdContext || this.savedLdContext}
                          options={{
                            bootstrap: initialLdFlags || this.savedLdFlags,
                            inspectors: [
                              {
                                type: 'flag-used',
                                name: 'dd-inspector',
                                method: (key, detail) => {
                                  // Adding feature flag evaluations to the events collected by datadog
                                  // https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/
                                  datadogRum.addFeatureFlagEvaluation(
                                    key,
                                    detail.value
                                  );
                                },
                              },
                            ],
                          }}
                        >
                          <IdentifyFlagsContext
                            context={buildLaunchDarklyContextFromUser(
                              this.authStore.user
                            )}
                          />
                          <ToastManager>
                            <ImpersonatingUserProviderWithUserId
                              onError={(err) => {
                                if (!isUnauthenticatedError(err)) {
                                  logException(err);
                                }
                              }}
                            >
                              <BillingEventsProvider user={this.authStore.user}>
                                <ForethoughtScript>
                                  <Component {...pageProps} />
                                </ForethoughtScript>
                              </BillingEventsProvider>
                            </ImpersonatingUserProviderWithUserId>
                          </ToastManager>
                        </LDProvider>
                      </MarketProvider>
                    </Hydrate>
                  </ApplicationNameContextProvider>
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="bottom-left"
                  />
                </QueryClientProvider>
              </Provider>
            </ThemeProvider>
          </CacheProvider>
        </HelixProvider>
      </HelixSSRProvider>
    );
  }
}

export default MyApp;
