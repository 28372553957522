import {
  createCalendar,
  getLocalTimeZone,
  now,
  parseZonedDateTime,
} from '@internationalized/date';
import { useSlotId } from '@react-aria/utils';
import { DatePickerProps, DateValue } from '@react-types/datepicker';
import React from 'react';
import { FocusRing, mergeProps, useDateField } from 'react-aria';
import { useDateFieldState } from 'react-stately';

import { DATA } from '../consts';
import { FieldError, FieldRoot } from '../fields';
import { Validator } from '../forms';
import { IconCalendarDots } from '../icons/CalendarDots';
import { useAssertFormParentEffect } from '../useAssertFormParentEffect';
import { useFieldValidity } from '../useFieldValidity';
import { FormInputProps, useFormInput } from '../useFormInput';
import { EditableDateTimeSegment, LiteralDateTimeSegment } from './common';

type DateFieldProps = {
  optionalityText?: React.ReactNode;
  constraints?: Validator<DateValue>[];
  /**
   * A placeholder date that determines the type of the DateValue used by the
   * component when value is undefined. Defaults to a ZonedDateTime representing
   * now() in the local time zone when the component is mounted.
   *
   * Think of this as coercing the type of DateValue used in onChange to a
   * specific type without having to use a controlled component.
   */
  placeholderValue?: DateValue;
} & FormInputProps<DateValue, DateValue | null> &
  Pick<DatePickerProps<DateValue>, 'minValue' | 'maxValue'>;

function DateField(props: DateFieldProps) {
  let { disabled, readonly } = props;
  const rootRef = React.useRef<HTMLDivElement>(null);

  let controlRef = React.useRef<HTMLDivElement>(null);
  let inputRef = React.useRef<HTMLInputElement | null>(null);

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      const segment = controlRef.current?.querySelector<HTMLDivElement>(
        '.hlx-date-segment-editable'
      );

      segment?.focus();
    },
  });

  const { ariaProps, hoverProps, isFocused, focusProps } = useFormInput({
    ...mergeProps(props, validationProps),
    isTextInput: true,
  });

  let state = useDateFieldState({
    ...props,
    ...ariaProps,
    onChange: (value) => {
      props.onChange?.(value);
    },
    /**
     * Setting the placeholder value to the current date in the local time zone
     * tells the component to use full ISO granularity when serializing the date.
     */
    placeholderValue: props.placeholderValue ?? now(getLocalTimeZone()),
    isDisabled: disabled,
    isReadOnly: readonly,
    hideTimeZone: true,
    granularity: 'day',
    locale: 'en-US',
    createCalendar,
  });

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  let {
    inputProps,
    labelProps,
    fieldProps,
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
  } = useDateField(
    {
      ...props,
      ...ariaProps,
      hideTimeZone: true,
      isDisabled: disabled,
      isReadOnly: readonly,
      granularity: 'day',
      inputRef,
    },
    state,
    controlRef
  );

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-date-field-root"
      isInvalid={isInvalid}
      isDisabled={disabled}
      isReadonly={readonly}
      {...fieldRootProps}
    >
      <div className="hlx-date-field-label" {...labelProps}>
        {props.label}
      </div>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
        within={true}
      >
        <div
          ref={controlRef}
          className="hlx-date-field-control"
          {...mergeProps(
            fieldProps,
            hoverProps,
            focusProps,
            {
              [DATA.FOCUSED]: isFocused,
            },
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string }
          )}
        >
          {state.segments.map((segment, i) => {
            if (segment.type === 'literal') {
              return <LiteralDateTimeSegment key={i} segment={segment} />;
            }

            return (
              <EditableDateTimeSegment
                key={i}
                segment={segment}
                state={state}
              />
            );
          })}
          {props.name && <input {...inputProps} ref={inputRef} />}
        </div>
      </FocusRing>

      <div className="hlx-date-field-adornment">
        <IconCalendarDots />
      </div>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-date-field-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-date-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      <FieldError
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        className="hlx-date-field-error"
        {...errorMessageProps}
      />
    </FieldRoot>
  );
}

export { DateField };
