import {
  createCalendar,
  DateValue,
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal,
  toCalendarDate,
} from '@internationalized/date';
import { useSlotId } from '@react-aria/utils';
import { DatePickerProps, Granularity } from '@react-types/datepicker';
import clsx from 'clsx';
import React from 'react';
import {
  AriaButtonProps,
  CalendarProps,
  FocusRing,
  mergeProps,
  useButton,
  useCalendar,
  useDateField,
  useDatePicker,
  useFocusRing,
} from 'react-aria';
import {
  DatePickerState,
  DatePickerStateOptions,
  useCalendarState,
  useDateFieldState,
  useDatePickerState,
} from 'react-stately';

import { DATA } from '../consts';
import { FieldError, FieldRoot } from '../fields';
import { Validator } from '../forms';
import { IconCaretDown } from '../icons/CaretDown';
import { useAssertFormParentEffect } from '../useAssertFormParentEffect';
import { useFieldValidity } from '../useFieldValidity';
import { FormInputProps, useFormInput } from '../useFormInput';
import { EditableDateTimeSegment, LiteralDateTimeSegment } from './common';
import { CalendarCellProps } from './DatePickerCalendarDialog';
import {
  DatePickerCalendar,
  DatePickerCalendarDialog,
} from './DatePickerCalendarDialog';

type DatePickerFieldProps = {
  optionalityText?: React.ReactNode;
  preset?: (state: DatePickerState) => React.ReactElement;
  constraints?: Validator<DateValue>[];
  /**
   * A placeholder date that determines the type of the DateValue used by the
   * component when value is undefined. Defaults to a ZonedDateTime representing
   * now() in the local time zone when the component is mounted.
   *
   * Think of this as coercing the type of DateValue used in onChange to a
   * specific type without having to use a controlled component.
   */
  placeholderValue?: DateValue;
} & FormInputProps<DateValue, DateValue | null> &
  Pick<CalendarCellProps, 'isDateSignificant'> &
  Pick<
    DatePickerProps<DateValue>,
    'minValue' | 'maxValue' | 'isDateUnavailable'
  >;

function DatePickerField(props: DatePickerFieldProps) {
  let controlRef = React.useRef<HTMLDivElement>(null);
  let rootRef = React.useRef<HTMLDivElement>(null);

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      const segment = controlRef.current?.querySelector<HTMLDivElement>(
        '.hlx-date-segment-editable'
      );
      segment?.focus();
    },
  });

  const { ariaProps, hoverProps } = useFormInput({
    ...props,
    isTextInput: true,
  });

  let { isFocused, isFocusVisible, focusProps } = useFocusRing({
    within: true,
    isTextInput: true,
    autoFocus: props.autoFocus,
  });

  let { isFocused: isFocusedButton, focusProps: focusPropsButton } =
    useFocusRing({
      within: false,
      isTextInput: false,
      autoFocus: props.autoFocus,
    });

  const options = mergeProps(ariaProps, validationProps, {
    minValue: props.minValue,
    maxValue: props.maxValue,
    onChange: (value) => {
      props.onChange?.(value);
    },
    /**
     * Setting the placeholder value to the current date in the local time zone
     * tells the component to use full ISO granularity when serializing the date.
     */
    placeholderValue: props.placeholderValue ?? now(getLocalTimeZone()),
    isDisabled: props.disabled,
    isReadOnly: props.readonly,
    hideTimeZone: true,
    locale: 'en-US',
    shouldCloseOnSelect: true,
  } as DatePickerStateOptions<DateValue>);

  const state = useDatePickerState(options);

  const {
    groupProps,
    labelProps,
    fieldProps,
    descriptionProps,
    errorMessageProps,
    buttonProps,
    dialogProps,
    calendarProps,
    isInvalid,
    validationErrors,
  } = useDatePicker(
    {
      ...options,
      // only pass granularity to useDatePicker. If we pass it to useDatePickerState,
      // the placeholderValue will be ignored and the date will be serialized with
      // day granularity rather than full ISO granularity.
      granularity: 'day',
    },
    state,
    controlRef
  );

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  return (
    <FieldRoot
      ref={rootRef}
      isInvalid={isInvalid}
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      className="hlx-date-picker-field-root"
      {...fieldRootProps}
    >
      <div className="hlx-date-picker-field-label" {...labelProps}>
        {props.label}
      </div>
      <div
        {...mergeProps(groupProps, hoverProps, focusProps, {
          [DATA.FOCUSED]: isFocused,
        })}
        ref={controlRef}
        className={clsx('hlx-date-picker-field-control-group', {
          focused: isFocused,
          'focus-ring': isFocusVisible && !isFocusedButton,
        })}
      >
        <DatePickerFieldControl
          {...mergeProps(
            fieldProps,
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string }
          )}
        />
        <CalendarTriggerButton {...mergeProps(buttonProps, focusPropsButton)}>
          <IconCaretDown size={16} />
        </CalendarTriggerButton>
      </div>
      {state.isOpen && (
        <DatePickerCalendarDialog
          state={state}
          triggerRef={controlRef}
          placement="bottom start"
          dialogProps={dialogProps}
        >
          {props.preset ? (
            <div className="hlx-date-picker-field-preset">
              {props.preset(state)}
            </div>
          ) : null}
          <CalendarView
            {...calendarProps}
            isDateSignificant={props.isDateSignificant}
          />
        </DatePickerCalendarDialog>
      )}

      {props.optionalityText && (
        <div
          id={optionalityId}
          className="hlx-date-picker-field-optionality-text"
        >
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-date-picker-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      <FieldError
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        className="hlx-date-picker-field-error"
        {...errorMessageProps}
      />
    </FieldRoot>
  );
}

export function CalendarView(
  props: CalendarProps<DateValue> & Pick<CalendarCellProps, 'isDateSignificant'>
) {
  let state = useCalendarState({
    ...props,
    locale: 'en-US',
    createCalendar,
  });

  let { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );

  React.useEffect(() => {
    if (props.value) {
      state.setFocusedDate(toCalendarDate(props.value));
    }
  }, [props.value]);

  return (
    <DatePickerCalendar
      state={state}
      calendarProps={calendarProps}
      nextMonthButtonProps={nextButtonProps}
      prevMonthButtonProps={prevButtonProps}
      isDateSignificant={props.isDateSignificant}
    />
  );
}

interface DatePickerFieldControlProps {}

/**
 * The DatePickerFieldControl is the actual input element that is rendered
 * inside the DatePickerField. It is responsible for rendering the input
 * control that the user interacts with to trigger the CalendarView popover.
 */
function DatePickerFieldControl(props: DatePickerFieldControlProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let inputRef = React.useRef<HTMLInputElement | null>(null);

  let state = useDateFieldState({
    ...props,
    locale: 'en-US',
    createCalendar,
  });

  let { fieldProps, inputProps } = useDateField(
    { ...props, inputRef },
    state,
    ref
  );

  return (
    <div ref={ref} className="hlx-date-picker-field-control" {...fieldProps}>
      {state.segments.map((segment, i) => {
        if (segment.type === 'literal') {
          return <LiteralDateTimeSegment key={i} segment={segment} />;
        }

        return (
          <EditableDateTimeSegment key={i} segment={segment} state={state} />
        );
      })}

      <input {...inputProps} ref={inputRef} />
    </div>
  );
}

const CalendarTriggerButton = React.forwardRef<
  HTMLButtonElement,
  AriaButtonProps<'button'>
>((props, ref) => {
  let { buttonProps } = useButton(
    props,
    ref as React.RefObject<HTMLButtonElement>
  );
  return (
    <FocusRing
      focusRingClass={'focus-ring'}
      focusClass="focused"
      autoFocus={props.autoFocus}
    >
      <button
        type="button"
        className="hlx-date-picker-field-trigger"
        {...buttonProps}
        ref={ref}
      >
        {props.children}
      </button>
    </FocusRing>
  );
});

export { DatePickerField };
