import { useSlotId } from '@react-aria/utils';
import { TimeValue } from '@react-types/datepicker';
import React from 'react';
import { FocusRing, mergeProps, useTimeField } from 'react-aria';
import { TimeFieldStateOptions, useTimeFieldState } from 'react-stately';

import { DATA } from '../consts';
import { FieldError, FieldRoot } from '../fields';
import { Validator } from '../forms';
import { useFieldValidity } from '../useFieldValidity';
import { FormInputProps, useFormInput } from '../useFormInput';
import { EditableDateTimeSegment, LiteralDateTimeSegment } from './common';

type TimeFieldProps = {
  optionalityText?: React.ReactNode;
  constraints?: Validator<TimeValue>[];
} & FormInputProps<TimeValue, TimeValue | null>;

function TimeField(props: TimeFieldProps) {
  let { disabled, readonly } = props;

  const rootRef = React.useRef<HTMLDivElement>(null);
  const controlRef = React.useRef<HTMLDivElement>(null);

  const { ariaProps, hoverProps, isFocused, focusProps } = useFormInput({
    ...props,
    isTextInput: true,
  });

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      const segment = controlRef.current?.querySelector<HTMLDivElement>(
        '.hlx-date-segment-editable'
      );

      segment?.focus();
    },
  });

  const options = mergeProps(
    {
      ...props,
      ...ariaProps,
      onChange: (value) => {
        props.onChange?.(value);
      },
      isDisabled: disabled,
      isReadOnly: readonly,
      hideTimeZone: true,
      locale: 'en-US',
      granularity: 'minute',
      hourCycle: 12,
    } as TimeFieldStateOptions,
    validationProps
  );

  let state = useTimeFieldState(options);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  let inputRef = React.useRef<HTMLInputElement | null>(null);

  let {
    labelProps,
    fieldProps,
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
    inputProps,
  } = useTimeField({ ...options, inputRef }, state, controlRef);

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-time-field-root"
      isDisabled={disabled}
      isInvalid={isInvalid}
      isReadonly={readonly}
      {...fieldRootProps}
    >
      <div className="hlx-time-field-label" {...labelProps}>
        {props.label}
      </div>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
        within={true}
      >
        <div
          ref={controlRef}
          className="hlx-time-field-control"
          {...mergeProps(
            fieldProps,
            hoverProps,
            focusProps,
            {
              [DATA.FOCUSED]: isFocused,
            },
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string }
          )}
        >
          {state.segments.map((segment, i) => {
            if (segment.type === 'literal') {
              return <LiteralDateTimeSegment key={i} segment={segment} />;
            }

            return (
              <EditableDateTimeSegment
                key={i}
                segment={segment}
                state={state}
              />
            );
          })}
          <input {...inputProps} ref={inputRef} />
        </div>
      </FocusRing>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-time-field-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-time-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      <FieldError
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        className="hlx-time-field-error"
        {...errorMessageProps}
      />
    </FieldRoot>
  );
}

export { TimeField };
