import { Sms } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { ForethoughtExternalWebhookApi } from '@headway/api/resources/ForethoughtExternalWebhookApi';
import { theme } from '@headway/helix/theme';

import { ZendeskWidget } from './ZendeskWidget';

export enum WidgetState {
  Closed,
  Forethought,
  ZendeskChatting,
  ZendeskChatEnded,
}

interface ForethoughtContextType {
  enabled: boolean;
  widgetState: WidgetState;
  position: 'left' | 'right';
  setWidgetState: (nextval: WidgetState) => void;
}

export interface ForethoughtScriptHandlerProps {
  apiKey?: string;
  zendeskAPIKey?: string;
  defaultWorkflowTag?:
    | 'I provide mental health services'
    | 'I receive mental health services';
  email: string;
  name: string;
  userID?: number;
  providerID?: number;
  autoOpen?: boolean;
  mambaHost?: string;
  token?: string;
  positionOffset?: number;

  liveChatEnabled: boolean;
  insuranceVerificationLiveChat: boolean;
  zendeskMessagingEnabled?: boolean;
}

const ForethoughtScriptHandler = ({
  apiKey,
  email,
  mambaHost,
  defaultWorkflowTag,
  name,
  userID,
  providerID,
  token,
  liveChatEnabled = false,
  insuranceVerificationLiveChat = false,
  zendeskMessagingEnabled = false,
}: ForethoughtScriptHandlerProps) => {
  const { position } = useContext(ForethoughtContext);
  useEffect(() => {
    if (apiKey && mambaHost && token) {
      const script: HTMLScriptElement = document.createElement('script');

      // https://findheadway.forethought.app/workflow-builder-config
      setScriptAttributes(script, {
        src: 'https://solve-widget.forethought.ai/embed.js',
        id: 'forethought-widget-embed-script',
        type: 'application/javascript',
        hidden: 'true',
        'hide-intercom-widget': 'false',
        'hide-ft-after-zd': 'true',

        'position-x': position,

        'data-api-key': apiKey,
        'data-ft-MambaHost': mambaHost.replace(/https?:\/\//, ''),
        'data-ft-UserToken': token,

        'data-ft-workflow-tag': defaultWorkflowTag,
        'data-ft-Full-Name': name,
        'data-ft-Email': email,
        'data-ft-UserID': `${userID}`,
        'data-ft-ProviderID': `${providerID}`,

        'data-ft-LiveChatEnabled': liveChatEnabled.toString(),
        'data-ft-insuranceVerificationLiveChat':
          insuranceVerificationLiveChat.toString(),
        'data-ft-ZendeskMessagingEnabled': zendeskMessagingEnabled.toString(),
      });

      document.body.append(script);
    }

    return () => {
      document.getElementById('forethought-widget-embed-script')?.remove();
    };
  }, [
    apiKey,
    email,
    name,
    defaultWorkflowTag,
    providerID,
    zendeskMessagingEnabled,
  ]);

  return null;
};

export const ForethoughtContext = React.createContext<ForethoughtContextType>({
  enabled: false,
  position: 'right',
  widgetState: WidgetState.Closed,
  setWidgetState: (nextVal: WidgetState) => {},
});

// Explicitly type the context to fix TypeScript errors
// @ts-ignore - Ignoring the TypeScript error for ForethoughtContext.Provider
ForethoughtContext.Provider =
  ForethoughtContext.Provider as React.Provider<ForethoughtContextType>;
// @ts-ignore - Ignoring the TypeScript error for ForethoughtContext.Consumer
ForethoughtContext.Consumer =
  ForethoughtContext.Consumer as React.Consumer<ForethoughtContextType>;

export function ForethoughtButton({
  positionOffset = 0,
  sx,
  ...props
}: IconButtonProps & { positionOffset?: number }) {
  const { position, enabled, widgetState, setWidgetState } =
    useContext(ForethoughtContext);

  if (!enabled || widgetState !== WidgetState.Closed) {
    return null;
  }

  return (
    <Tooltip title="Virtual assistant">
      <IconButton
        aria-label="Virtual assistant"
        size="large"
        onClick={() => setWidgetState(WidgetState.Forethought)}
        sx={{
          position: 'fixed',
          margin: '24px 28px',
          bottom: 0,
          [position]: `${positionOffset}px`,
          zIndex: theme.layers.base + 1,
          backgroundColor: `${theme.color.system.black} !important`,
          color: theme.color.system.white,

          '&:hover': {
            backgroundColor: '#4D4C4D',
          },

          ...sx,
        }}
        {...props}
      >
        <Sms />
      </IconButton>
    </Tooltip>
  );
}

export const ForethoughtScriptProvider = ({
  apiKey,
  defaultWorkflowTag,
  email,
  name,
  userID,
  providerID,
  autoOpen,
  mambaHost,
  position = 'right',
  children,
  liveChatEnabled = false,
  insuranceVerificationLiveChat = false,
  zendeskAPIKey,
  zendeskMessagingEnabled = false,
}: ForethoughtScriptHandlerProps & {
  autoOpen?: boolean;
  position?: 'left' | 'right';
  children: React.ReactNode;
}) => {
  const [widgetState, setWidgetState] = useState(WidgetState.Closed);
  const [isTokenLoading, setIsTokenLoading] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isForethoughtReady, setIsForethoughtReady] = useState(false);

  useEffect(() => {
    setToken(undefined);
    if (!userID) {
      return;
    }

    setIsTokenLoading(true);

    ForethoughtExternalWebhookApi.getChatbotToken()
      .then((token) => {
        setIsTokenLoading(false);
        if (!token) {
          return;
        }

        setToken(token);
      })
      .catch((err) => {
        setIsTokenLoading(false);
      });
  }, [userID, providerID]);

  useEffect(() => {
    function handleForethoughtMessage(e: MessageEvent<any>) {
      switch (e.data.event) {
        case 'forethoughtWidgetClosed':
          setWidgetState(WidgetState.Closed);
          break;
        case 'forethoughtWidgetOpened':
          setWidgetState(WidgetState.Forethought);
          break;
        case 'forethoughtWidgetLoaded':
          if (autoOpen) {
            setWidgetState(WidgetState.Forethought);
          }

          setIsForethoughtReady(true);
          break;
      }
    }

    window.addEventListener('message', handleForethoughtMessage);

    return () =>
      window.removeEventListener('message', handleForethoughtMessage);
  }, []);

  useEffect(() => {
    if (!isForethoughtReady) {
      return;
    }

    if (widgetState === WidgetState.Forethought) {
      safeForethought('widget', 'open');
      safeForethought('widget', 'show');
    } else {
      safeForethought('widget', 'hide');
      safeForethought('widget', 'close');
    }
  }, [widgetState, isForethoughtReady]);

  return (
    <ForethoughtContext.Provider
      value={{
        enabled: !!token,
        position,
        widgetState: widgetState,
        setWidgetState: setWidgetState,
      }}
    >
      {children}
      {!isTokenLoading && token && (
        <>
          <ForethoughtScriptHandler
            apiKey={apiKey}
            defaultWorkflowTag={defaultWorkflowTag}
            email={email}
            name={name}
            userID={userID}
            providerID={providerID}
            mambaHost={mambaHost}
            token={token}
            liveChatEnabled={liveChatEnabled}
            insuranceVerificationLiveChat={insuranceVerificationLiveChat}
            zendeskMessagingEnabled={zendeskMessagingEnabled}
          />
          {liveChatEnabled && !zendeskMessagingEnabled && (
            <ForethoughtContext.Consumer>
              {({ widgetState, setWidgetState }) => (
                <ZendeskWidget
                  apiKey={zendeskAPIKey}
                  name={name}
                  email={email}
                  settings={{ position: { horizontal: position } }}
                  hideWidgetLauncher={
                    !(
                      widgetState === WidgetState.ZendeskChatting ||
                      widgetState === WidgetState.ZendeskChatEnded
                    )
                  }
                  onChatStart={() => {
                    setWidgetState(WidgetState.ZendeskChatting);
                  }}
                  onChatEnd={() => {
                    setWidgetState(WidgetState.ZendeskChatEnded);
                  }}
                  onWidgetClose={() => {
                    if (widgetState === WidgetState.ZendeskChatEnded) {
                      setWidgetState(WidgetState.Closed);
                    }
                  }}
                  onUnreadMessage={() => {
                    //If you get a new unread message assume we're chatting
                    // that way we don't accidentally show 2 chat bubbles at once
                    if (widgetState !== WidgetState.ZendeskChatting) {
                      setWidgetState(WidgetState.ZendeskChatting);
                    }
                  }}
                />
              )}
            </ForethoughtContext.Consumer>
          )}
        </>
      )}
    </ForethoughtContext.Provider>
  );
};

function setScriptAttributes(
  script: HTMLScriptElement,
  attributes: { [key: string]: string | undefined }
) {
  Object.entries(attributes).forEach(([key, value]) => {
    if (value) {
      script.setAttribute(key, value);
    }
  });
}

function safeForethought(
  selector: 'widget',
  action: 'hide' | 'show' | 'open' | 'close',
  data?: unknown
) {
  const Forethought = (window as any)!.Forethought;
  if (!Forethought) {
    return;
  }
  Forethought(selector, action, data);
}
