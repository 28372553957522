import { HttpStatusCode, isAxiosError } from 'axios';

import {
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
  UseQueryResult,
} from '@headway/shared/react-query';

export function isSuccessQuery<T>(
  query: UseQueryResult<T>
): query is QueryObserverSuccessResult<T> {
  return query.isSuccess;
}

export const isUnauthenticatedError = (err: unknown): boolean => {
  if (!isAxiosError(err)) {
    return false;
  }
  return err.response?.status === HttpStatusCode.Unauthorized;
};

export const isDuplicateRequestError = (err: unknown): boolean => {
  if (!isAxiosError(err)) {
    return false;
  }
  return err.response?.status === HttpStatusCode.Conflict;
};

export type QueriesByStatus<TData, TError> = {
  success: QueryObserverSuccessResult<TData, TError>[];
  error: (
    | QueryObserverLoadingErrorResult<TData, TError>
    | QueryObserverRefetchErrorResult<TData, TError>
  )[];
  loading: QueryObserverLoadingResult<TData, TError>[];
};

export function groupQueriesByStatus<TData, TError>(
  queries: UseQueryResult<TData, TError>[]
): QueriesByStatus<TData, TError> {
  const map: QueriesByStatus<TData, TError> = {
    success: [],
    error: [],
    loading: [],
  };

  for (const query of queries) {
    if (isSuccessQuery(query)) {
      map['success'].push(query);
    } else if (query.isLoading) {
      map['loading'].push(query);
    } else {
      map['error'].push(query);
    }
  }

  return map;
}
